/* eslint-disable react/button-has-type */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import LoadingOverlay from "./LoadingOverlay";

const UniversalLogin = ({ onLoginSuccess, successfulUniversalLoginCode }) => {
  const allValues = useAuth0();
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } = allValues; // logout, user
  const [triggerLogin, setTriggerLogin] = useState(false);
  const [triggerRedirect, setTriggerRedirect] = useState(false);
  const showLogOutButton = false;

  const redirectToApplication = () => {
    getAccessTokenSilently({
      authorizationParams: {
        scope: "read:current_user",
      },
    }).then((e) => onLoginSuccess({ accessToken: e }));
  };

  useEffect(() => {
    if (!triggerLogin && !isAuthenticated && !successfulUniversalLoginCode) {
      setTriggerLogin(true);
      loginWithRedirect();
    }
  }, [loginWithRedirect, isAuthenticated, triggerLogin]);

  useEffect(() => {
    if (isAuthenticated && successfulUniversalLoginCode && !triggerRedirect && !showLogOutButton) {
      setTriggerRedirect(true);
      redirectToApplication(successfulUniversalLoginCode);
    }
  }, [successfulUniversalLoginCode, isAuthenticated]);

  if (showLogOutButton) {
    return (
      <div>
        {!isAuthenticated ? (
          <button id="qsLoginBtn" color="primary" className="btn-margin" onClick={() => loginWithRedirect()}>
            Log in
          </button>
        ) : (
          <>
            <button id="qsLoginBtn" color="primary" className="btn-margin" onClick={() => logout()}>
              logout
            </button>
          </>
        )}
      </div>
    );
  }
  return <LoadingOverlay />;
};

export default UniversalLogin;
