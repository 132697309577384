/* eslint-disable react/button-has-type */
import React, { useEffect, useCallback, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth0Provider } from "@auth0/auth0-react";

import fetch from "isomorphic-unfetch";
import { ApolloClient, InMemoryCache, gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Auth0LockPasswordless } from "auth0-lock";
import { get, pickBy, cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@fivehealth/botero";
import styled from "styled-components";
import { PublicClientApplication } from "@azure/msal-browser";
import LoadingOverlay from "./LoadingOverlay";
import UniversalLogin from "./UniversalLogin";

import QrCode from "./QrCode";

import publicConfig from "./publicConfig.json";
import msalConfig from "./microsoftADConfig";
import MicrosoftLogin from "./MicrosoftLogin";
import InternalLogin from "./internalLogin";

const urlParams = new URLSearchParams(window.location.search);
const gqlUri = urlParams.get("gql_uri");
const isUniversalLogin = urlParams.get("universalLogin") || false;
const successfulUniversalLoginCode = urlParams.get("code") || null;

const parseApplicationInput = (key, data) => {
  if (!data && key) {
    if (key === "application_input") {
      return JSON.parse(localStorage.getItem(key));
    }
    if (key) {
      return localStorage.getItem(key);
    }
    return null;
  }
  try {
    localStorage.setItem(key, data);
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

const getGqlUri = () => {
  if (gqlUri) {
    parseApplicationInput("gql_uri", urlParams.get("gql_uri"));
    return gqlUri;
  }
  if (localStorage.getItem("gql_uri")) {
    return localStorage.getItem("gql_uri");
  }
  if (publicConfig && publicConfig.gqlUri) return publicConfig.gqlUri;
  return "https://example.com/gql/"; // for test cases
};

const apolloClientUri = getGqlUri();

export const client = new ApolloClient({
  uri: apolloClientUri,
  fetchOptions: { fetch }, // for MS IE compatibility
  cache: new InMemoryCache(),
});

export const PROVIDER_APPLICATION_GQL = gql`
  query providerApplication($uid: String!) {
    heimdallProviderApplication(uid: $uid) {
      settings
      uid
    }
  }
`;

export const AUTHORIZATION_FLOW_GQL = gql`
  mutation authorizationFlow($input: HeimdallAuthorizationFlowInput!) {
    heimdallAuthorizationFlow(input: $input) {
      redirectTo
    }
  }
`;

export const HEIMDALL_SESSION_GQL = gql`
  query byUid($uid: String!) {
    heimdallSession(uid: $uid) {
      id
      uid
      user {
        uid
      }
      scopes
      expiresOn
    }
  }
`;

const StyledContainer = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorBoundary = ({ error }) => (
  // TODO: report error to sentry
  <div role="alert">
    <p>Something went wrong: </p>
    <pre style={{ color: "red" }}>{error.message}</pre>
  </div>
);

const parseUid = (props) => {
  if (!props.uid) {
    if (urlParams.get("uid")) {
      localStorage.setItem("uid", urlParams.get("uid"));
      return urlParams.get("uid");
    }
    if (localStorage.getItem("uid")) {
      return localStorage.getItem("uid");
    }
    return null;
  }
  localStorage.setItem("uid", props.uid);
  return props.uid;
};

const qrCodeSessionToken = uuidv4().split("-").join("");
const qrCodeUrl = `botmd://hospital/qrcodeLogin?session=${qrCodeSessionToken}`;
let HEIMDALL_CALL_TIMEOUT = 0;
let heimdallCallInterval;

const Login = ({ onAuthenticate, ...props }) => {
  const [uid] = useState(parseUid(props));
  const [applicationInput] = useState(parseApplicationInput("application_input", urlParams.get("applicationInput")));
  const themeConfig = parseApplicationInput("theme", urlParams.get("theme"));
  const { redirectTo } = props;
  const [showADLogin, setShowADLogin] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [qrCodeSessionExpired, setQrCodeSessionExpired] = useState(false);
  const [isHeimdalOtpEmail, setIsHeimdalOtpEmail] = useState(false);
  const [providerConf, setProviderConf] = useState(null); // 296

  const qrcode = !!urlParams.get("qrcode");
  const [clientId] = useState(parseApplicationInput("client_id", urlParams.get("client_id")));

  if (redirectTo) {
    localStorage.setItem("redirect_to", redirectTo);
  }

  const onThrowError = useCallback((errorStr) => {
    const error = Error(errorStr);
    setShowError(error);
  }, []);

  const configureADConfig = () => {
    const clientid = localStorage.getItem("client_id") ? localStorage.getItem("client_id") : "";

    // todo: check for client id when backend api is ready
    // if (!clientid && urlParams.get("client_id")) onThrowError("ClientId could not be stored, please check permissions");

    const redirectUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

    const ADConfig = msalConfig;
    ADConfig.auth.clientId = clientid || clientId;
    ADConfig.auth.redirectUri = redirectUrl;
    return ADConfig;
  };

  const msalInstance = new PublicClientApplication(configureADConfig());

  const onLoginRedirect = useCallback(
    (data) => {
      const {
        heimdallAuthorizationFlow: { redirectTo: redirect },
      } = data;
      const session = redirect ? redirect.split("session=")[1] : "";
      if (!redirect) {
        return onThrowError("Redirect is null.");
      }
      if (session) {
        window.parent.postMessage(
          {
            session,
          },
          "*"
        );
      }
      window.location.assign(redirect);
      return true;
    },
    [onThrowError, redirectTo]
  );

  const [authorizationFlowMutation, { data: authorizationSuccessData }] = useMutation(AUTHORIZATION_FLOW_GQL, {
    onSuccess: () => {
      // delete localstorage keys
      localStorage.removeItem("redirect_to");
      localStorage.removeItem("applicationInput");
      localStorage.removeItem("uid");
      localStorage.removeItem("client_id");
      localStorage.removeItem("gql_uri");
    },
    onError: (error) => onThrowError(`Error performing authorization flow: ${error}`),
  });

  const onLoginSuccess = useCallback(
    (auth0Result) => {
      // Show loading overlay after
      // checkmark animation completes
      setTimeout(() => {
        setShowLoadingOverlay(true);
      }, 1600);
      const applicationInputClone = cloneDeep(applicationInput);
      if (applicationInputClone?.organization_key === "testktph") {
        applicationInputClone.organization_key = "ktph";
      }
      const providerInput = isHeimdalOtpEmail ? { token: auth0Result.accessToken } : { access_token: auth0Result.accessToken };
      authorizationFlowMutation({
        variables: {
          input: pickBy({
            providerApplicationUid: uid,
            applicationInput: applicationInputClone,
            providerInput,
            redirectTo: redirectTo || localStorage.getItem("redirect_to"),
          }),
        },
      });
    },
    // eslint-disable-next-line
    [uid, redirectTo, authorizationFlowMutation, isHeimdalOtpEmail]
  );

  const onShowLogin = useCallback(
    (auth0Settings) => {
      const lock = new Auth0LockPasswordless(auth0Settings.clientId, auth0Settings.domain, {
        ...auth0Settings.options,
        container: "lock-container",
        auth: {
          redirect: false,
        },
        passwordlessMethod: "code",
        allowedConnections: [auth0Settings.connection],
        mustAcceptTerms: false,
        theme: {
          ...get(auth0Settings.options, "theme", {}),
          ...(themeConfig || {}),
        },
      });
      lock.on("authenticated", onLoginSuccess);
      lock.show();
    },
    // eslint-disable-next-line
    [onLoginSuccess]
  );

  const { data: providerApplicationData } = useQuery(PROVIDER_APPLICATION_GQL, {
    variables: { uid },
    onError: (error) => onThrowError(`Error getting provider application: ${error}`),
  });

  const [getSession, { error, data: heimdallSessionResponse }] = useLazyQuery(HEIMDALL_SESSION_GQL, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (qrcode && !qrCodeSessionExpired) {
      heimdallCallInterval = setInterval(() => {
        HEIMDALL_CALL_TIMEOUT += 3;
        if (HEIMDALL_CALL_TIMEOUT >= 180) {
          clearInterval(heimdallCallInterval);
          setQrCodeSessionExpired(true);
          return;
        }
        getSession({
          variables: { uid: qrCodeSessionToken },
        });
      }, 3000);
    }
    if (qrCodeSessionExpired) {
      HEIMDALL_CALL_TIMEOUT = 0;
      clearInterval(heimdallCallInterval);
    }
    // eslint-disable-next-line
  }, [qrCodeSessionExpired]);

  useEffect(() => {
    if (heimdallSessionResponse && heimdallSessionResponse.heimdallSession) {
      clearInterval(heimdallCallInterval);
      const { heimdallSession } = heimdallSessionResponse;
      if (heimdallSession) {
        onLoginRedirect({
          heimdallAuthorizationFlow: {
            redirectTo: `${redirectTo}?session=${heimdallSession.uid}`,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [heimdallSessionResponse]);

  useEffect(() => {
    if (providerApplicationData) {
      const {
        heimdallProviderApplication: {
          settings: { auth0: auth0Settings },
        },
      } = providerApplicationData;

      if (gqlUri && applicationInput && (applicationInput.organization_key === "testcgh" || applicationInput.organization_key === "testktph")) {
        return setIsHeimdalOtpEmail(true);
      }

      if (auth0Settings && auth0Settings?.clientId) {
        const providerConfig = {
          domain: auth0Settings?.domain,
          clientId: auth0Settings?.clientId,
          // params passed to authorizationParams can be accessed in auth0 Branding => Universal login => Advance login
          authorizationParams: {
            redirect_uri: window?.location?.origin,
            logo: themeConfig?.logo || "",
            languageDictionary: JSON.stringify({
              title: "",
              signUpTerms: "By signing up, you agree to our <a href='https://botmd.com/en/legal.html#terms-and-conditions'>terms of service</a> and <a href='https://botmd.com/en/legal.html#privacy-policy'>privacy policy</a>.",
              passwordlessSMSInstructions: "For login support issues,please contact customer support@botmd.io",
            }),
          },
        };
        setProviderConf(providerConfig);
      }

      if (auth0Settings && !isUniversalLogin && !successfulUniversalLoginCode) return onShowLogin(auth0Settings);
      if (applicationInput && (applicationInput.organization_key === "cgh" || applicationInput.organization_key === "ttsh" || applicationInput.organization_key === "nccs" || applicationInput.organization_key === "sgh") && !showADLogin) setShowADLogin(true);
    }
    return true;
  }, [providerApplicationData, onShowLogin, applicationInput, showADLogin]);

  useEffect(() => {
    if (authorizationSuccessData) {
      onLoginRedirect(authorizationSuccessData);
    }
  }, [authorizationSuccessData, onLoginRedirect]);

  useEffect(() => {
    if (!uid) {
      onThrowError("UID not set.");
    }
  }, [uid, onThrowError]);

  const reload = () => {
    setQrCodeSessionExpired(false);
  };

  if (showError && !showADLogin) {
    return <ErrorBoundary error={showError} />;
  }

  if ((isUniversalLogin || successfulUniversalLoginCode) && providerConf && providerConf.clientId) {
    return (
      <Auth0Provider {...providerConf}>
        <UniversalLogin providerConf={providerConf} onLoginSuccess={onLoginSuccess} successfulUniversalLoginCode={successfulUniversalLoginCode} />
      </Auth0Provider>
    );
  }

  // Return customized lock screen
  return (
    <StyledContainer>
      {showLoadingOverlay && <LoadingOverlay />}
      {showADLogin && !error && (
        <MsalProvider instance={msalInstance}>
          <MicrosoftLogin msalInstance={msalInstance} onThrowError={onThrowError} orgKey={applicationInput.organization_key} />
        </MsalProvider>
      )}
      {!showADLogin && !isHeimdalOtpEmail && <div id="lock-container" style={{ display: qrcode ? "none" : "block" }} />}
      {!showADLogin && isHeimdalOtpEmail && <InternalLogin onThrowError={onThrowError} onLoginSuccess={onLoginSuccess} uid={uid} providerApplicationData={providerApplicationData} />}
      {qrcode && <QrCode value={qrCodeUrl} error={error} qrCodeSessionExpired={qrCodeSessionExpired} reload={reload} />}
    </StyledContainer>
  );
};

export default React.memo(Login);
